import React from 'react'

import './style/data-request-policy.scss'

const DataRequestPolicy = () => (
  <div className='row-wrapper copy-right data-request-policy'>
    <div className='container'>
      <section className='policy-agree'>
        <div className='row'>
          <div className='col-sm-12'>
            <h2>Data Request Policy</h2>
          </div>
        </div>
      </section>
    </div>

    <div className='container tos'>
      <div className='row'>
        <div className='col-xs-12'>
          <ul className='legal'>
            <li className='sub-title' id='PRIVACYPOLICY'>
              <p><b>Privacy Notice.</b>
              Please see WebsiteBuilder’s <a href='https://newfold.com/privacy-center' rel='noopener' target='_blank'>Privacy Notice</a> for further information
            on what type of information WebsiteBuilder collects, how it is used and when it may be disclosed. Subject to WebsiteBuilder’s Privacy Notice, this Data
            Request Policy describes the types of requests for data that WebsiteBuilder may receive and how WebsiteBuilder may respond to such requests.
              </p>
            </li>

            <li className='sub-title' id='TypesofRequests'>
              <p><b>Types of Requests. </b>
              WebsiteBuilder may receive the following types of requests concerning its Subscribers:
              </p>
            </li>

            <ul>
              <li className='item' id='ADataRequests'>
                <p><b>Data Requests.</b> A data request is a request for information or documents relating to
                 Subscriber accounts in connection with official criminal investigations or
                 other legal proceedings. Except in limited emergency situations (see below),
                 WebsiteBuilder requires data requests to be made through formal U.S. legal process and procedures, and such requests will be handled in accordance with applicable law. Examples of acceptable data requests include:</p>
              </li>

              <ul className='sub-lists'>
                <li className='item' id='iSubpoenas'>
                  <p>Subpoenas</p>
                </li>

                <li className='item' id='iiCourtOrders'>
                  <p>Court Orders</p>
                </li>

                <li className='item' id='iiiSearchWarran'>
                  <p>Search Warrants</p>
                </li>

                <li className='item' id='ivLegalProcess'>
                  <p>Legal process received from outside the United States may require a Mutual Legal Assistance Treaty (MLAT) with the United States</p>
                </li>

                <li className='item' id='vOtherFormsofLe'>
                  <p>Other forms of legal process such as Civil Investigative Demands</p>
                </li>

              </ul>

              <li className='item' id='BPreservationRe'>
                <p><b>Preservation Requests.</b> A preservation request asks WebsiteBuilder to preserve Subscriber account
                 records in connection with official criminal investigations or other official legal proceedings.
                 Preservation requests must include the following information:</p>
              </li>

              <ul className='sub-lists'>

                <li className='item' id='iIdentification'>
                  <p>Identification of the account(s) at issue (as further described in Section 6 below);</p>
                </li>

                <li className='item' id='iiIdentificatio'>
                  <p><b>Identification of the investigating agency and/or specific pending official proceedings (requests must be signed on law enforcement letterhead);</b></p>
                </li>

                <li className='item' id='iiiAssurancesth'>
                  <p><b>Assurances that the requesting agency or individual is taking steps to obtain appropriate legal process for access to the data that WebsiteBuilder is being asked to preserve; and </b></p>
                </li>

                <li className='item' id='ivAvalidreturne'>
                  <p><b>A valid return email address and phone number.</b></p>
                </li>

              </ul>

              <li className='item' id='CEmergencyReque'>
                <p><b>Emergency Requests.</b> An emergency request is only appropriate in cases involving imminent serious bodily harm or death. We respond to emergency
                requests when we believe in good faith that such harm may occur if we do not respond without delay.</p>
              </li>

            </ul>

            <li className='sub-title' id='NOTICETO'>
              <p><b>Disclosure of Non-Public Domain Registrant Information.</b>
              As the sponsoring registrar or reseller of certain domain names governed by the Internet Corporation for Assigned Names and Numbers (“ICANN”),
              web must permit third parties with a legitimate interest to access masked (non-public) registrant information subject to
              the data subject’s rights under the General Data Protection Regulation (“GDPR”) or other similar privacy laws as applicable.
              </p>
            </li>

            <li className='sub-title' id='NOTICETO'>
              <p><b>Notice to WebsiteBuilder Subscribers.</b>
              WebsiteBuilder’s policy is to notify its Subscribers of requests for their data unless
              WebsiteBuilder is prohibited from doing so by statute or court order.
              </p>
            </li>

            <li className='sub-title' id='INFORMATIONTOIN'>
              <p><b>Information to Include in a Request.</b>
              The following information must be included in a request for Subscriber  data:
              </p>

              <ul>
                <li className='item' id='AFirstandlastna'>
                  <p>First and last name of the Subscriber and email address associated with the account; and </p>
                </li>

                <li className='item' id='BDomainnameand'>
                  <p>Domain name, username and/or IP address associated with the account. </p>
                </li>

                <p /><p>
                  <b>NOTE:</b>
                  A Request that only includes an IP address may be overly broad and insufficient for
                  WebsiteBuilder to locate a Subscriber account. Please include additional identifiers such domain name
                   or username whenever possible.</p>
                <p /><p>WebsiteBuilder may not be able to respond to a request without this information.</p>

                <p>Additionally, WebsiteBuilder reserves the right to request
                a copy of the complaint and any supporting documentation that demonstrates how the information requested is related to the pending litigation and the underlying subpoena.</p><p />

              </ul>
            </li>

            <li className='sub-title' id='SENDINGAREQUEST'>
              <p><b>Sending a Request to WebsiteBuilder. </b>All data requests should be sent to:</p>
            </li>

            <p>WebsiteBuilder</p> <p>Legal Department</p><p>5335 Gate Pkwy, 2nd Floor</p><p>Jacksonville, FL 32256</p>
            <p>Phone: (602) 716-5396 (M-F 7am - 3:30pm MST)</p>
            <p>Fax: (904) 659-8905 </p>
            <p>E-mail: support@websitebuilder.com</p>
            <p><b>IMPORTANT NOTE: IN THE EVENT YOU SEND US A NOTICE OF ANY KIND VIA EMAIL
              AND DO NOT RECEIVE A RESPONSE FROM WEBSITEBUILDER, PLEASE SUBMIT A DUPLICATE COPY VIA MAIL.
              DUE TO THE VAGARIES OF THE INTERNET AND EMAIL COMMUNICATION IN PARTICULAR,
              INCLUDING THE OCCASIONAL UNINTENDED EFFECTS OF SPAM FILTERS,
              SENDING AN ALTERNATE FORM OF NOTICE WILL HELP ASSURE THAT YOUR NOTICE IS RECEIVED BY Websitebuilder
              AND ACTED ON IN A TIMELY MANNER.</b></p><p />

            <li className='sub-title' id='FEES'>
              <p><b>Fees.</b>
              WebsiteBuilder may seek reimbursement for costs in responding to requests as provided by law and may charge additional fees for costs in responding to unusual or burdensome requests.
              </p>
            </li>

            <li className='sub-title' id='AVAILABLEINFORM'>
              <p><b>Available Information. </b>Subject to receiving a valid request, WebsiteBuilder may provide data as follows: </p>
            </li>

            <ul>

              <li className='item' id='ANonContent'>
                <p><b>Non-Content.</b> WebsiteBuilder will produce non-content information such as basic subscriber information which may include the following subject to a valid request:</p>
              </li>

              <ul className='sub-lists'>

                <li className='item' id='iName'>
                  <p><b>Name</b></p>
                </li>

                <li className='item' id='iiEmailaddresse'>
                  <p><b>Email addresses</b></p>
                </li>

                <li className='item' id='iiiDateandtimeo'>
                  <p><b>Date and time of account creation</b></p>
                </li>

                <li className='item' id='ivBillinginform'>
                  <p><b>Billing information</b></p>
                </li>

                <li className='item' id='vIPLogs'>
                  <p><b>IP Logs</b></p>
                </li>

              </ul>

              <li className='item' id='BContent'>
                <p><b>Content.</b> WebsiteBuilder will only produce Subscriber content (such as website files and email content) pursuant to a valid search warrant from an entity with proper jurisdiction.</p>
              </li>

            </ul>

            <p><span className='small'>This file was last modified: October 07, 2022.</span></p>

          </ul>
        </div>
      </div>
    </div>
  </div>)

export default DataRequestPolicy
